<template>
  <v-flex :xs12="option.hasFreeText">
    <v-layout v-if="inputWidget === 'TimeAgo'">
      <v-flex xs3>
        <v-checkbox
          :label="option.text"
          :input-value="value"
          :on-icon="
            question.multiple ? $vuetify.icons.checkboxOn : 'mdi-check-circle'
          "
          :off-icon="
            question.multiple
              ? $vuetify.icons.checkboxOff
              : 'mdi-checkbox-blank-circle-outline'
          "
          color="primary"
          hide-details
          @change="onChange"
        />
      </v-flex>
      <v-flex xs9>
        <TimeAgoText v-model="text" :disabled="!value" @input="onChange" />
      </v-flex>
    </v-layout>
    <v-layout v-else>
      <v-checkbox
        :label="option.text"
        :input-value="value"
        :on-icon="
          question.multiple ? $vuetify.icons.checkboxOn : 'mdi-check-circle'
        "
        :off-icon="
          question.multiple
            ? $vuetify.icons.checkboxOff
            : 'mdi-checkbox-blank-circle-outline'
        "
        color="primary"
        hide-details
        @change="onChange"
      />
      <v-text-field
        v-if="option.hasFreeText"
        v-model="text"
        :disabled="!value"
        :full-width="false"
        :placeholder="
          (extra.props && extra.props.placeholder) || 'ご記入ください'
        "
        :suffix="extra.props && extra.props.suffix"
        :style="extra.style"
        counter="100"
        class="ml-3"
        @input="onChange"
      />
    </v-layout>
  </v-flex>
</template>

<script>
import TimeAgoText from './CounselingQuestionOptionTimeAgoText'
export default {
  components: {
    TimeAgoText,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    question: {
      type: Object,
      required: true,
    },
    option: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    localText: '',
  }),
  computed: {
    extra() {
      return this.option.extra || {}
    },
    inputWidget() {
      let extra = this.option.extra
      return extra && extra.inputWidget
    },
    text: {
      get() {
        if (this.localText) return this.localText
        if (this.value) return this.value.text
        return ''
      },
      set(value) {
        this.localText = value
      },
    },
  },
  methods: {
    onChange(value) {
      // console.log(this.option.text, value)
      if (value)
        this.$emit('input', {
          question: this.question.id,
          option: this.option.id,
          text: this.text,
        })
      else this.$emit('input', null)
    },
  },
}
</script>
