<template>
  <div>
    <CounselingQuestion
      v-for="q in questions"
      :key="q.id"
      :question="q"
      :value="getQuestionAnswer(q.id)"
      @input="setQuestionAnswer(q.id, $event)"
    />
  </div>
</template>

<script>
import CounselingQuestion from './CounselingQuestion'
export default {
  name: 'CounselingQuestionAnswerForm',
  components: {
    CounselingQuestion,
  },
  model: {
    prop: 'values',
    event: 'input',
  },
  props: {
    values: {
      type: Array,
      default: () => [],
    },
    questions: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getQuestionAnswer(id) {
      return this.values.filter(a => a.question === id)
    },
    setQuestionAnswer(id, values) {
      // console.log('set Ans', id, 'len', values.length)
      let results = this.values.filter(a => a.question !== id).concat(values)
      this.$emit('input', results)
    },
  },
}
</script>
