<template>
  <v-menu offset-y max-height="300px">
    <template #activator="{ on }">
      <v-btn
        color="secondary"
        :disabled="!postcode"
        :loading="loading"
        @click="search"
        v-on="on"
      >
        住所検索
      </v-btn>
    </template>
    <v-list v-if="results.length > 0">
      <v-list-item
        v-for="(result, index) in results"
        :key="index"
        @click="select(result)"
      >
        <v-list-item-title
          >{{ result.pref }} {{ result.city }} {{ result.town }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { setTimeout } from 'timers'

async function sleep(ms) {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}

export default {
  name: 'PostcodeSearch',
  props: {
    postcode: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    results: [],
    loading: false,
  }),
  methods: {
    async search() {
      this.loading = true
      try {
        this.results = []
        let results = await this.searchPostcode(this.postcode)
        // console.log(results)
        let len = results.length
        if (len === 0) {
          this.$toast.error('郵便番号が見つかりませんでした。')
        } else if (len === 1) {
          this.$emit('result', results[0])
        } else {
          this.results = results
        }
      } catch (err) {
        // console.error(err)
        this.$toast.error('サーバエラーが発生しました。')
      }
      this.loading = false
    },

    async searchPostcode(postcode) {
      const request = async (cursor, results) => {
        let params = {
          postcode,
          apiKey: process.env.VUE_APP_POSTCODE_API_KEY,
          normalize: true,
          general: true,
          office: true,
          limit: 20,
        }
        if (cursor) params.cursor = cursor
        let {
          data,
          nextCursor,
        } = await this.$api.axios
          .get('https://apis.postcode-jp.com/api/v2/postcodes', { params })
          .then(res => res.data)
        let rval = results.concat(data)
        if (nextCursor) {
          await sleep(900)
          return request(nextCursor, rval)
        } else return rval
      }
      return await request(null, [])
    },

    select(result) {
      this.$emit('result', result)
      this.results = []
    },
  },
}
</script>
