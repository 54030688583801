<template>
  <v-layout column fill-height>
    <v-toolbar flat>
      <v-toolbar-title>
        顧客管理
      </v-toolbar-title>
      <v-spacer />
      <CSVExport
        v-if="$store.getters.superuserLoggedIn"
        :total-count="totalCount"
        :fetch="() => $api.customers().list(fetchParams)"
        :headers="csvHeaders"
        :disabled="questionLoading"
        filename-prefix="customer-"
      />
      <v-divider class="mx-2" vertical />
      <v-btn color="primary" to="/customer/create">
        新規登録
      </v-btn>
    </v-toolbar>
    <v-row>
      <v-col xs="12" sm="3">
        <v-text-field
          v-model="filter.search"
          outlined
          label="検索"
          placeholder="会員番号, 氏名, 電話番号"
          hide-details
          append-icon="search"
          clearable
          @click:clear="clearSearch"
          @click:append="updateQuery"
          @keypress.enter="updateQuery"
        />
      </v-col>
      <v-col xs="6" sm="3" md="2">
        <ShopAutocomplete
          v-model="filter.shop"
          outlined
          label="店舗"
          hide-details
          clearable
          @change="updateQuery"
        />
      </v-col>

      <v-col xs="6" sm="3" md="2">
        <DateSelect
          v-model="filter.registeredFrom"
          outlined
          label="登録日 FROM"
          clearable
          hide-details
          @input="updateQuery"
        />
      </v-col>
      <v-col xs="6" sm="3" md="2">
        <DateSelect
          v-model="filter.registeredTo"
          outlined
          label="登録日 TO"
          clearable
          hide-details
          @input="updateQuery"
        />
      </v-col>
      <v-col xs="6" sm="3" md="2">
        <SelectNullBoolean
          v-model="filter.hasUser"
          label="アプリ登録"
          @input="updateQuery"
        />
      </v-col>
    </v-row>
    <virtual-data-table
      :items="customers"
      :identifier="identifier"
      :total-count="totalCount"
      :ordering.sync="filter.ordering"
      @update:ordering="updateQuery"
      @infinite="fetchData"
    >
      <virtual-data-table-column
        label="会員番号"
        prop="account"
        width="100"
        fixed="left"
      >
        <template #default="props">
          <a @click="pushDetail(props)">{{ props.item.account }}</a>
        </template>
      </virtual-data-table-column>

      <virtual-data-table-column label="店舗" prop="shopName" />
      <virtual-data-table-column
        label="セイ"
        prop="familyNameKana"
        width="100"
      />
      <virtual-data-table-column
        label="メイ"
        prop="givenNameKana"
        width="100"
      />
      <virtual-data-table-column label="氏名" width="150">
        <template #default="props">
          {{ props.item.familyName }}
          {{ props.item.givenName }}
        </template>
      </virtual-data-table-column>
      <virtual-data-table-column
        label="電話番号"
        prop="phoneNumber"
        width="150"
      />
      <virtual-data-table-column label="アプリ氏名">
        <template #default="{ item: { userdata } }">
          <span v-if="userdata">
            {{ userdata.familyName }}
            {{ userdata.firstName }}
            ({{ userdata.familyNameKana }} {{ userdata.firstNameKana }})
          </span>
        </template>
      </virtual-data-table-column>
      <virtual-data-table-column
        label="アプリUID"
        prop="userdata.uid"
        width="200"
      />
      <virtual-data-table-column
        label="アプリEmail"
        prop="userdata.email"
        width="300"
      />
      <virtual-data-table-column
        label="アプリ電話番号"
        prop="userdata.phoneNumber"
        width="150"
      />
      <virtual-data-table-column
        label="初回来店日"
        prop="aggregation.firstVisit"
        width="100"
      />
      <virtual-data-table-column
        label="最終来店日"
        prop="aggregation.lastVisit"
        width="100"
      />
      <virtual-data-table-column
        label="来店回数"
        prop="aggregation.visitCount"
        width="100"
      />
      <virtual-data-table-column
        label="初回来店経路"
        prop="aggregation.firstReservedBy"
        width="100"
      />
      <virtual-data-table-column
        label="最多来店店舗"
        prop="aggregation.maxVisitShopName"
        width="100"
      />
      <virtual-data-table-column label="登録日時" prop="createdAt" width="150">
        <template #default="{ item: { createdAt } }">
          {{ createdAt | datetime }}
        </template>
      </virtual-data-table-column>
      <virtual-data-table-column label="更新日時" prop="updatedAt" width="150">
        <template #default="{ item: { createdAt } }">
          {{ createdAt | datetime }}
        </template>
      </virtual-data-table-column>
    </virtual-data-table>
    <router-view />
  </v-layout>
</template>

<script>
import moment from 'moment'
import SelectNullBoolean from '@/components/SelectNullBoolean.vue'
import CSVExport from '@/components/CSVExport.vue'
import DateSelect from '@/components/DateSelect.vue'
import ShopAutocomplete from '@/components/ShopAutocomplete.vue'

import FilterUtil from '@/filter-util'
const filterUtil = new FilterUtil({
  search: { type: String, default: null },
  ordering: { type: String, default: '' },
  hasUser: { type: Boolean, default: null },
  registeredFrom: { type: String, default: null },
  registeredTo: { type: String, default: null },
  shop: { type: String, default: null },
})
const extractTime = value => (value ? moment(value).format('HH:mm') : '')
const extractDate = value => (value ? moment(value).format('YYYY-MM-DD') : '')
const CSVHeaders = [
  { key: 'uuid', label: 'id' },
  { key: 'shopCode', label: '店舗コード' },
  { key: 'shopName', label: '店舗名' },
  { key: 'familyName', label: '姓' },
  { key: 'givenName', label: '名' },
  { key: 'familyNameKana', label: 'セイ' },
  { key: 'givenNameKana', label: 'メイ' },
  { key: 'account', label: 'お客様番号' },
  { key: 'userdata.email', label: 'アプリユーザ' },
  { key: 'email', label: 'Eメール' },
  { key: 'age', label: '年代' },
  { key: 'gender', label: '性別' },
  { key: 'phoneNumber', label: '電話番号' },
  { key: 'postcode', label: '郵便番号' },
  { key: 'prefecture', label: '都道府県' },
  { key: 'city', label: '市町村' },
  { key: 'address', label: '町名番地' },
  { key: 'building', label: 'ビル名等' },
  { key: 'note', label: 'お客さまメモ' },
  { key: 'aggregation.maxVisitShopName', label: '最多来店店舗' },
  { key: 'aggregation.firstVisit', label: '初回来店日' },
  { key: 'aggregation.lastVisit', label: '最終来店日' },
  { key: 'aggregation.visitCount', label: '来店回数' },
  { key: 'aggregation.firstReservedBy', label: '初回来店経路' },
  {
    key: item => (item.receivesInfo ? '受け取る' : '受け取らない'),
    label: 'fufuからのお知らせを受け取る',
  },

  { key: item => extractDate(item.createdAt), label: '作成日' },
  {
    key: item => extractTime(item.createdAt),
    label: '作成時刻',
  },
  { key: item => Boolean(item.starterPassFrom), label: 'スターターパス購入' },
  { key: item => item.starterPassFrom, label: 'スターターパス購入日' },
  { key: item => item.birthday, label: '誕生日' },

  { key: item => extractDate(item.updatedAt), label: '更新日' },
  {
    key: item => extractTime(item.updatedAt),
    label: '更新時刻',
  },
  { key: 'aggregation.firstVisitShopCode', label: '初回来店店舗コード' },
  { key: 'aggregation.firstVisitShopName', label: '初回来店店舗名' },
]

export default {
  name: 'CustomerView',
  components: {
    SelectNullBoolean,
    CSVExport,
    DateSelect,
    ShopAutocomplete,
  },
  data: () => ({
    filter: {
      search: null,
      ordering: '',
      hasUser: null,
      registeredFrom: null,
      registeredTo: null,
      shop: null,
    },
    query: null,
    customers: [],
    next: null,
    totalCount: 0,

    questionLoading: false,
    counselingQuestions: [],
  }),
  computed: {
    identifier() {
      return JSON.stringify(this.query)
    },
    csvHeaders() {
      const q1 = this.counselingQuestions.find(
        x => x.shortText === 'fufuを知ったきっかけ' && x.version === '2'
      )
      const q13 = this.counselingQuestions.find(
        x => x.shortText === 'fufuを知ったきっかけ' && x.version === '3'
      )
      let q2 = this.counselingQuestions.find(
        x => x.shortText === 'fufuに感じた良さ'
      )
      let old = [
        {
          key: item => {
            let answers = item.counselingAnswers
            if (answers.length === 0) {
              return item.knownReasonTexts.join(',')
            }
            let id2opt = {}
            q1.options.forEach(o => {
              id2opt[o.id] = o
            })
            q13?.options.forEach(o => {
              id2opt[o.id] = o
            })
            return answers
              .filter(x => x.question === q1.id || x.question == q13?.id)
              .map(ans => {
                let opt = id2opt[ans.option]
                if (!opt) {
                  console.error(opt, ans.option, id2opt)
                  return ''
                }
                return ans.text ? `${opt.text}: ${ans.text}` : opt.text
              })
              .join(', ')
          },
          label: 'fufuを知ったきっかけ',
        },
        { key: 'visitReasonOther', label: 'fufuを知ったきっかけ(その他)' },
        {
          key: item => {
            let answers = item.counselingAnswers
            if (answers.length === 0) {
              return item.visitReasonTexts.join(',')
            }
            let id2opt = {}
            q2.options.forEach(o => {
              id2opt[o.id] = o
            })
            return answers
              .filter(x => x.question === q2.id)
              .map(ans => {
                let opt = id2opt[ans.option]
                return ans.text ? `${opt.text}: ${ans.text}` : opt.text
              })
              .join(', ')
          },
          label: '来店のきっかけ',
        },
        { key: 'knownReasonOther', label: '来店のきっかけ(その他)' },
      ]
      return CSVHeaders.concat(old)
    },
    fetchParams() {
      let params = Object.assign(this.query)
      if (params.registeredFrom) {
        let m = moment(params.registeredFrom)
        if (m.isValid()) params.registeredFrom = m.toISOString()
        else delete params.registeredFrom
      }
      if (params.registeredTo) {
        let m = moment(params.registeredTo)
        if (m.isValid()) {
          m.add(1, 'days')
          params.registeredTo = m.toISOString()
        } else delete params.registeredTo
      }
      return params
    },
  },
  watch: {
    '$route.query'(query) {
      this.query = filterUtil.parseQueryDict(query)
    },
    identifier() {
      this.customers = []
      this.next = null
      this.getTotalCount()
    },
  },
  created() {
    this.query = filterUtil.parseQueryDict(this.$route.query)
    Object.assign(this.filter, this.query)
    this.getTotalCount()
    this.fetchQuestions()
  },
  methods: {
    async fetchQuestions(url) {
      this.questionLoading = true
      let { results, next } = await (url
        ? this.$api.http.get(url)
        : this.$apiV3.counselingQuestions().list({ all: true }))
      this.counselingQuestions = this.counselingQuestions.concat(results)
      if (next) this.fetchQuestions(next)
      else this.questionLoading = false
    },
    async getTotalCount() {
      this.totalCount = await this.$api.customers().count(this.fetchParams)
    },
    async fetchData($state) {
      try {
        let { results, next } = await (this.next
          ? this.$api.http.get(this.next)
          : this.$api.customers().list(this.fetchParams))
        this.customers = this.customers.concat(results)
        this.next = next
        if (this.customers.length) $state.loaded()
        if (!this.next) $state.complete()
      } catch (err) {
        $state.error(err)
      }
    },
    updateQuery() {
      let query = filterUtil.mergeQueryDict(this.query, this.filter)
      this.$router.push({ query })
    },
    clearSearch() {
      this.filter.search = ''
      this.updateQuery()
    },
    pushDetail(row) {
      this.$router.push({
        name: 'customer-detail',
        params: { id: row.item.uuid },
        query: this.$route.query,
      })
    },
  },

  metaInfo() {
    return { title: '顧客管理' }
  },
}
</script>

<style></style>
