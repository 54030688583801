<script>
import CustomerAutocomplete from '@/components/CustomerAutocomplete.vue'

export default {
  name: 'CustomerMergePage',
  components: {
    CustomerAutocomplete,
  },
  data() {
    return {
      form: {
        customer: '',
        oldCustomer: '',
        user: null,
      },
      customer: null,
      oldCustomer: null,
      isLoading: false,
      errors: {},
    }
  },
  computed: {
    userChoiceAlert() {
      return (
        Boolean(this.customer?.fufuUser && this.oldCustomer?.fufuUser) &&
        !this.form.user
      )
    },
    hasError() {
      return Object.keys(this.errors).length > 0
    },
    customerFields() {
      return [
        {
          text: 'お客さま番号',
          key: 'account',
        },
        {
          text: '登録店舗',
          key: 'shopName',
        },
        {
          text: '姓',
          key: 'familyName',
        },
        {
          text: '名',
          key: 'givenName',
        },
        {
          text: 'セイ',
          key: 'familyNameKana',
        },
        {
          text: 'メイ',
          key: 'givenNameKana',
        },
        {
          text: 'Email',
          key: 'email',
        },
        {
          text: 'TEL',
          key: 'phoneNumber',
        },
        {
          text: '性別',
          key: 'gender',
        },
        {
          text: '誕生日',
          key: 'birthday',
        },
        {
          text: '住所',
          key: this.getAddress,
        },
        {
          text: 'スターターパス有効期限',
          key: 'starterPassTo',
        },
        {
          text: '備考',
          key: 'note',
        },
        {
          text: '施術の注意点',
          key: 'operationNote',
        },
        {
          text: '来店回数',
          key: 'aggregation.visitCount',
        },
        {
          text: 'カウンセリングシート記入済',
          key: this.isCounselingSheetWritten,
        },
        {
          text: '作成日時',
          key: 'createdAt',
        },
        {
          text: '更新日時',
          key: 'updatedAt',
        },
      ]
    },
    userFields() {
      return [
        {
          text: 'Email',
          key: 'email',
        },
        {
          text: 'TEL',
          key: 'phoneNumber',
        },
        {
          text: '姓',
          key: 'familyName',
        },
        {
          text: '名',
          key: 'givenName',
        },
        {
          text: 'セイ',
          key: 'familyNameKana',
        },
        {
          text: 'メイ',
          key: 'givenNameKana',
        },

        {
          text: '作成日時',
          key: 'createdAt',
        },
        {
          text: '更新日時',
          key: 'updatedAt',
        },
      ]
    },
  },
  watch: {
    customer() {
      this.resetUser()
    },
    oldCustomer() {
      this.resetUser()
    },
  },
  methods: {
    resetUser() {
      const u = this.customer?.fufuUser
      const old = this.oldCustomer?.fufuUser
      if (u && old) {
        this.form.user = null
      } else if (u) {
        this.form.user = u
      } else if (old) {
        this.form.user = old
      } else {
        this.form.user = null
      }
    },
    getValue(item, key) {
      if (typeof key === 'function') return key(item)
      let keys = key.split('.')
      let len = keys.length
      let obj = item
      for (let idx = 0; idx < len; ++idx) {
        if (!obj) return obj
        if (typeof obj !== 'object') return obj
        obj = obj[keys[idx]]
      }
      return obj
    },
    isCounselingSheetWritten(item) {
      if (!item) return
      return item.patchTest !== null
    },
    getAddress(cus) {
      if (!cus) return
      return `${cus.postcode} ${cus.prefecture}${cus.city}${cus.address}${cus.building}`
    },
    swap() {
      const form = this.form
      const cusId = form.customer
      form.customer = form.oldCustomer
      form.oldCustomer = cusId
      const cus = this.customer
      this.customer = this.oldCustomer
      this.oldCustomer = cus
    },

    async submit() {
      const cus = this.customer
      const old = this.oldCustomer
      if (
        !confirm(
          `顧客情報${old.account}を${cus.account}に統合します。\n${old.account}は削除されます。\nこの操作は元に戻せません。\n統合を実行しますか？`
        )
      )
        return
      try {
        this.errors = {}
        this.isLoading = true
        await this.$api.customers().merge(this.form)
        this.$toast.success('アカウント統合を実行しました。')
        this.form.user = null
        this.form.customer = null
        this.form.oldCustomer = null
      } catch (err) {
        this.$toast.error('アカウント統合に失敗しました。')
        this.errors = this.$getValidationErrors(err)
        console.error(err)
      }
      this.isLoading = false
    },
  },
}
</script>

<template>
  <v-card flat>
    <v-card-title>
      名寄
    </v-card-title>
    <v-card-text>
      <CustomerAutocomplete
        v-model="form.customer"
        outlined
        label="残す顧客"
        clearable
        @customer="customer = $event"
      />
      <v-btn class="mb-4" @click="swap">
        <v-icon>
          mdi-swap-vertical-bold
        </v-icon>
      </v-btn>

      <CustomerAutocomplete
        v-model="form.oldCustomer"
        outlined
        label="消す顧客"
        clearable
        @customer="oldCustomer = $event"
      />
      <v-alert type="warning" prominent :value="userChoiceAlert">
        両方の顧客情報がアプリユーザと紐付けされています。
        <br />
        使用するアプリユーザを選択してください。
      </v-alert>
    </v-card-text>
    <v-subheader>顧客情報</v-subheader>
    <v-simple-table>
      <thead>
        <tr>
          <th />
          <th>残す方</th>
          <th>削除する方</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(field, idx) in customerFields" :key="idx">
          <th v-text="field.text" />
          <td v-text="getValue(customer, field.key)" />
          <td v-text="getValue(oldCustomer, field.key)" />
        </tr>
      </tbody>
    </v-simple-table>

    <v-subheader>アプリユーザ</v-subheader>
    <v-simple-table>
      <thead>
        <tr>
          <th>アプリユーザ選択</th>
          <th>
            <v-checkbox
              v-if="customer && customer.fufuUser"
              v-model="form.user"
              :value="customer.fufuUser"
            />
          </th>
          <th>
            <v-checkbox
              v-if="oldCustomer && oldCustomer.fufuUser"
              v-model="form.user"
              :value="oldCustomer.fufuUser"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(field, idx) in userFields" :key="idx">
          <th v-text="field.text" />
          <td v-text="getValue(customer && customer.userdata, field.key)" />
          <td
            v-text="getValue(oldCustomer && oldCustomer.userdata, field.key)"
          />
        </tr>
      </tbody>
    </v-simple-table>

    <v-alert type="error" :value="hasError">
      <template v-for="(val, key) in errors">
        <p v-for="(e, idx) in val" :key="`${key}-${idx}`" class="mb-0">
          {{ e }}
        </p>
      </template>
    </v-alert>

    <v-card-actions>
      <v-spacer />
      <v-btn
        color="error"
        :disabled="userChoiceAlert"
        :loading="isLoading"
        @click="submit"
      >
        統合実行
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
