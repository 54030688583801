<template>
  <v-form>
    <v-row wrap>
      <v-col cols="12">
        <v-select
          v-if="shops.length !== 0"
          v-model="form.shop"
          label="店舗"
          :error-messages="errors.shop"
          :items="shops"
          :item-text="shop => `${shop.code} ${shop.name}`"
          item-value="id"
          @blur="emit"
        />
        <span v-else>
          {{ value && value.shopName }}
        </span>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="form.familyNameKana"
          label="セイ"
          :error-messages="errors.familyNameKana"
          @blur="emit"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="form.givenNameKana"
          label="メイ"
          :error-messages="errors.givenNameKana"
          @blur="emit"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="form.familyName"
          label="姓"
          :error-messages="errors.familyName"
          @blur="emit"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="form.givenName"
          label="名"
          :error-messages="errors.givenName"
          @blur="emit"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="form.phoneNumber"
          label="電話番号"
          type="tel"
          :error-messages="errors.phoneNumber"
          @blur="emit"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="form.email"
          label="メールアドレス"
          :error-messages="errors.email"
          @blur="emit"
        />
      </v-col>
      <v-col cols="3">
        <v-select
          v-model="form.age"
          label="年齢"
          :items="['10代', '20代', '30代', '40代', '50代', '60代以上']"
          :error-messages="errors.age"
          @change="emit"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="form.birthday"
          type="date"
          label="誕生日"
          clearable
          :error-messages="errors.birthday"
          @change="emit"
        />
      </v-col>
      <v-col cols="3">
        <v-select
          v-model="form.gender"
          label="性別"
          :items="['女性', '男性']"
          :error-messages="errors.gender"
          @change="emit"
        />
      </v-col>
      <v-col cols="3">
        <v-select
          v-model="form.patchTest"
          label="パッチテスト"
          :items="[
            { text: '未記入', value: null },
            { text: '実施する', value: true },
            { text: '実施しない', value: false },
          ]"
          :error-messages="errors.patchTest"
          @change="emit"
        />
      </v-col>
      <v-col cols="3">
        <v-checkbox
          v-model="form.receivesInfo"
          label="fufuからのクーポン情報やお知らせを受け取る"
          :error-messages="errors.receivesInfo"
          @change="emit"
        />
      </v-col>
      <v-col cols="6">
        <v-textarea
          v-model="form.note"
          label="備考"
          outlined
          :error-messages="errors.note"
          @blur="emit"
        />
      </v-col>
      <v-col cols="6">
        <v-textarea
          v-model="form.operationNote"
          outlined
          label="施術の注意点"
          :error-messages="errors.operationNote"
          @blur="emit"
        />
      </v-col>
      <!-- <v-col>
        <DateSelect
          v-model="form.starterPassFrom"
          label="スターターパス購入日"
          :error-messages="errors.starterPassFrom"
          clearable
          @change="emit"
        />
      </v-col> -->
    </v-row>
  </v-form>
</template>

<script>
// import DateSelect from '@/components/DateSelect'
export default {
  name: 'CustomerFormBasic',
  components: {
    // DateSelect,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    shops: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    form: {
      shop: null,
      familyName: '',
      givenName: '',
      familyNameKana: '',
      givenNameKana: '',
      age: '',
      birthday: null,
      gender: '',
      phoneNumber: '',
      email: '',
      receivesInfo: false,
      patchText: null,
      note: null,
      operationNote: '',
      starterPassFrom: null,
    },
  }),
  watch: {
    value: {
      immediate: true,
      handler(value) {
        let form = this.form
        Object.keys(form).forEach(k => {
          if (k in value) {
            form[k] = value[k]
          }
        })
      },
    },
  },
  methods: {
    emit() {
      const form = this.form
      if (!form.birthday) form.birthday = null
      this.form.givenNameKana = this.hiraToKana(this.form.givenNameKana)
      this.form.familyNameKana = this.hiraToKana(this.form.familyNameKana)
      let value = null
      if (this.value) value = Object.assign({}, this.value, this.form)
      else value = Object.assign({}, this.form)
      this.$emit('input', value)
    },
    hiraToKana(str) {
      return str.replace(/[\u3041-\u3096]/g, match => {
        let chr = match.charCodeAt(0) + 0x60
        return String.fromCharCode(chr)
      })
    },
  },
}
</script>
