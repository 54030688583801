<template>
  <v-dialog fullscreen :value="true" :transition="false">
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn
          icon
          dark
          :to="{ name: 'customer-list', query: $route.query }"
          exact
        >
          <v-icon>arrow_back_ios</v-icon>
        </v-btn>
        <v-toolbar-title>
          顧客編集 #{{ customer && customer.account }}
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            text
            :to="{ name: 'customer-detail', query: $route.query }"
            exact
          >
            戻る
          </v-btn>
          <v-btn text :loading="loading" @click="submit">
            更新
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-subheader>基本情報</v-subheader>
      <v-card-text>
        <CustomerFormBasic v-model="form" :errors="errors" />
      </v-card-text>
      <v-subheader>住所</v-subheader>
      <v-card-text>
        <AddressForm v-model="form" :errors="errors" />
      </v-card-text>
      <v-divider />
      <v-subheader>アンケート</v-subheader>
      <v-card-text>
        <CounselingAnswerForm
          v-model="form.counselingAnswers"
          :questions="questions"
          :errors="errors.counselingAnswers"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CounselingAnswerForm from '@/components/CounselingQuestionAnswerForm'
import CustomerFormBasic from '@/components/CustomerFormBasic.vue'
import AddressForm from '@/components/AddressForm.vue'

export default {
  name: 'CustomerEditView',
  components: {
    CustomerFormBasic,
    AddressForm,
    CounselingAnswerForm,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    customer: null,
    questions: [],
    form: {},
    errors: {},
    loading: false,
  }),
  computed: {
    userdata() {
      if (!this.customer) return null
      return this.customer.userdata
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.customer = await this.$api.customers(this.id).get()
      this.form = Object.assign({}, this.customer)
      this.questions = await this.$api
        .counselingQuestions()
        .list()
        .then(({ results }) => results)
    },
    async submit() {
      try {
        this.loading = true
        this.errors = {}
        await this.$api.customers(this.id).update(this.form)
        this.$router.push({ name: 'customer-detail', params: { id: this.id } })
      } catch (err) {
        console.error(err)
        this.errors = this.$getValidationErrors(err)
      }
      this.loading = false
    },
  },
}
</script>

<style></style>
