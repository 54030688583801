<template>
  <v-form>
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="form.postcode"
          v-mask="'###-####'"
          label="郵便番号"
          :error-messages="errors.postcode"
          @blur="emit"
        >
          <template #append>
            <PostcodeSearch
              :postcode="form.postcode"
              @result="onSearchPostcodeResult"
            />
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="form.prefecture"
          label="都道府県"
          :error-messages="errors.prefecture"
          @blur="emit"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="form.city"
          label="市区町村"
          :error-messages="errors.city"
          @blur="emit"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="form.address"
          label="町名番地"
          :error-messages="errors.address"
          @blur="emit"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="form.building"
          label="建物名"
          :error-messages="errors.building"
          @blur="emit"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import PostcodeSearch from '@/components/PostcodeSearch'

export default {
  name: 'AddressForm',
  components: {
    PostcodeSearch,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    form: {
      postcode: '',
      prefecture: '',
      city: '',
      address: '',
      building: '',
    },
  }),
  watch: {
    value: {
      immediate: true,
      handler(value) {
        let form = this.form
        Object.keys(form).forEach(k => {
          if (k in value) {
            form[k] = value[k]
          }
        })
      },
    },
  },
  methods: {
    emit() {
      let value = null
      if (this.value) value = Object.assign({}, this.value, this.form)
      else value = Object.assign({}, this.form)
      this.$emit('input', value)
    },
    onSearchPostcodeResult(result) {
      let { pref, city, town } = result
      let form = this.form
      form.prefecture = pref
      form.city = city
      form.address = town
    },
  },
}
</script>
