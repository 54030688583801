<template>
  <div>
    <span v-html="ln(question.text)" />
    <v-layout wrap class="px-3">
      <QuestionOption
        v-for="opt in options.filter(x => x.isActive)"
        :key="opt.id"
        :question="question"
        :option="opt"
        :value="getValue(opt.id)"
        @input="onChange(opt, $event)"
      />
    </v-layout>
  </div>
</template>

<script>
import QuestionOption from './CounselingQuestionOption'
export default {
  name: 'CounselingQuestion',
  components: {
    QuestionOption,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    options() {
      return this.question.options
    },
  },
  methods: {
    getValue(id) {
      return this.value.find(x => x.option === id)
    },
    onChange(opt, value) {
      if (this.question.multiple) {
        let id = opt.id
        let values = this.value.filter(x => x.option !== id)
        if (value) {
          values.push(value)
        }
        this.$emit('input', values)
      } else {
        if (value) this.$emit('input', [value])
        else this.$emit('input', [])
      }
    },
    ln(value) {
      return String(value)
        .split('\n')
        .join('<br />')
    },
  },
}
</script>
