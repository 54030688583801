<template>
  <v-dialog
    fullscreen
    :value="value"
    :transition="false"
    @input="$emit('input', $event)"
  >
    <template #activator="props">
      <slot name="activator" v-bind="props" />
    </template>
    <v-card :loading="loading" :disabled="loading">
      <v-toolbar dark color="primary">
        <v-btn icon dark exact @click="$emit('click-back', $event)">
          <v-icon>arrow_back_ios</v-icon>
        </v-btn>
        <v-toolbar-title>
          顧客新規登録
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn text :loading="loading" @click="submit">
            登録
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-subheader>基本情報</v-subheader>
      <v-card-text>
        <CustomerFormBasic v-model="form" :errors="errors" :shops="shops" />
      </v-card-text>
      <v-subheader>住所</v-subheader>
      <v-card-text>
        <AddressForm v-model="form" :errors="errors" />
      </v-card-text>

      <v-divider />
      <v-subheader>アンケート</v-subheader>
      <v-card-text>
        <CounselingAnswerForm
          v-model="form.counselingAnswers"
          :questions="questions"
          :errors="errors.counselingAnswers"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import CustomerFormBasic from '@/components/CustomerFormBasic.vue'
import CounselingAnswerForm from '@/components/CounselingQuestionAnswerForm'
import AddressForm from '@/components/AddressForm.vue'

export default {
  name: 'CustomerCreateDialog',
  components: {
    CustomerFormBasic,
    CounselingAnswerForm,
    AddressForm,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    form: {
      shop: null,
      counselingAnswers: [],
    },
    errors: {},
    shops: [],
    questions: [],
    loading: false,
  }),
  computed: {
    ...mapGetters(['user']),
  },
  created() {
    this.fetchShops()
    this.fetchQuestions()
    let user = this.user
    if (user && user.shop) {
      this.form.shop = user.shop.id
    }
  },
  methods: {
    async fetchShops(url) {
      try {
        let { results, next } = await (url
          ? this.$api.http.get(url)
          : this.$api.shops().list({ ordering: 'code' }))
        this.shops = this.shops.concat(results)
        if (next) this.fetchShops(next)
      } catch (err) {
        console.error(err)
        this.$toast.error('店舗一覧の取得に失敗しました。')
      }
    },
    async fetchQuestions() {
      this.questions = await this.$api
        .counselingQuestions()
        .list()
        .then(({ results }) => results)
    },
    async submit() {
      try {
        this.loading = true
        this.errors = {}
        let data = await this.$api.customers().create(this.form)
        this.$emit('created', data)
      } catch (err) {
        console.error(err)
        this.errors = this.$getValidationErrors(err)
        this.loading = false
      }
    },
  },
}
</script>
