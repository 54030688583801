
import { UsedDyestuff1, UsedDyestuff2 } from '@/types'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class UsedDyestuffs extends Vue {
  @Prop({ type: Array, default: null }) readonly dyestuffs!: UsedDyestuff1[]
  @Prop({ type: Array, default: null }) readonly solutions!: UsedDyestuff2[]
  @Prop({ type: Number, default: null }) readonly dyestuffAmount!: null | number
  @Prop({ type: Boolean, default: false }) readonly bold!: boolean

  getDyestuffBGColor(brand: string) {
    if (brand === 'イノア') return '#B9F6CA'
    return null
  }
  getDyestuffFontColor(brand: string) {
    if (brand === 'イノア') return 'black'
    return null
  }

  getAmount(amount: number | null) {
    if (amount) {
      if (this.dyestuffAmount) {
        return Math.floor(amount * 0.9)
      }
    }
    return amount
  }
}
