<template>
  <v-layout>
    <v-select
      v-model="num"
      label="時期"
      :items="items"
      :disabled="disabled"
      style="max-width: 150px"
    />
    <v-radio-group v-model="suffix" row :disabled="disabled">
      <v-radio color="primary" label="日前" value="日前" />
      <v-radio color="primary" label="ヶ月間前" value="ヶ月前" />
    </v-radio-group>
  </v-layout>
</template>

<script>
export default {
  name: 'TimeAgoText',
  props: {
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    localNum: '',
    localSuffix: '',
  }),
  computed: {
    items() {
      let r = []
      for (let i = 1; i <= 30; ++i) r.push(i)
      return r
    },
    num: {
      get() {
        if (this.localNum) return this.localNum
        let m = this.value.match(/^\d+/)
        if (m) return Number(m[0])
        return ''
      },
      set(value) {
        this.localNum = value
        this.onChange()
      },
    },
    suffix: {
      get() {
        if (this.localSuffix) return this.localSuffix
        let m = this.value.match(/(^\d+)(.+)/)
        if (m) return m[2]
        return ''
      },
      set(value) {
        this.localSuffix = value
        this.onChange()
      },
    },
  },
  methods: {
    onChange() {
      // console.log('onChange', this.num + this.suffix)
      if (this.num && this.suffix) this.$emit('input', this.num + this.suffix)
    },
  },
}
</script>
