<template>
  <CustomerCreateDialog
    :value="true"
    @created="onCreated"
    @click-back="onClickBack"
  />
</template>

<script>
import CustomerCreateDialog from '@/components/CustomerCreateDialog'
export default {
  name: 'CustomerCreateView',
  components: {
    CustomerCreateDialog,
  },
  methods: {
    onClickBack() {
      this.$router.push({ name: 'customer-list', query: this.$route.query })
    },
    onCreated(data) {
      this.$router.push(`/customer/${data.uuid}/edit`)
    },
  },
}
</script>
