<template>
  <v-dialog fullscreen :transition="false" :value="true">
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn
          icon
          dark
          :to="{ name: 'customer-list', query: $route.query }"
          exact
        >
          <v-icon>arrow_back_ios</v-icon>
        </v-btn>
        <v-toolbar-title>顧客詳細</v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn text :to="`/customer/${id}/edit`">
            編集
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <template v-if="customer">
        <v-subheader>基本情報</v-subheader>
        <v-card-text>
          <v-row>
            <template v-for="(item, idx) in basicData">
              <v-col
                :key="idx + '-text'"
                cols="6"
                md="3"
                lg="2"
                class="grey lighten-3 text-end"
              >
                {{ item.text }}
              </v-col>
              <v-col :key="idx + '-value'" cols="6" md="3" lg="2">
                {{ item.value }}
              </v-col>
            </template>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-subheader>アンケート</v-subheader>
        <v-card-text>
          <v-row>
            <template v-for="(item, idx) in counselingAnswers">
              <v-col
                :key="idx + '-text'"
                cols="6"
                md="3"
                class="grey lighten-3 text-end"
              >
                {{ item.text }}
              </v-col>
              <v-col :key="idx + '-value'" cols="6" md="3">
                {{ item.value }}
              </v-col>
            </template>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-subheader>アプリユーザ</v-subheader>
        <v-card-text>
          <v-layout v-if="userdata" wrap>
            <v-flex v-for="item in userItems" :key="item.prop">
              {{ item.text }}
              <v-spacer />
              {{ userdata[item.prop] }}
            </v-flex>
          </v-layout>
          <span v-else>未登録</span>
        </v-card-text>

        <v-divider />
        <v-subheader>施術履歴</v-subheader>
        <v-card-text>
          <div ref="tableContainer" />
          <OperationHistoryTable
            :customer="customer.uuid"
            :height="tableHeight"
          />
        </v-card-text>
        <div ref="bottom" />
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import OperationHistoryTable from '@/components/Reservation/OperationHistoryTable'

export default {
  name: 'CustomerDetailView',
  components: {
    OperationHistoryTable,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    customer: null,
    fields: [],
    userItems: [
      {
        text: 'UID',
        prop: 'uid',
      },
      {
        text: 'email',
        prop: 'username',
      },
      {
        text: 'TEL',
        prop: 'phoneNumber',
      },
      {
        text: '姓',
        prop: 'familyName',
      },
      {
        text: '名',
        prop: 'firstName',
      },
      {
        text: 'セイ',
        prop: 'familyNameKana',
      },
      {
        text: 'メイ',
        prop: 'firstNameKana',
      },
    ],
    questions: [],
    tableHeight: '300px',
  }),
  computed: {
    userdata() {
      if (!this.customer) return null
      return this.customer.userdata
    },
    basicData() {
      let c = this.customer
      if (!c) return []
      return [
        { text: '会員番号', value: c.account },
        { text: '登録店舗', value: c.shopName },
        { text: '名前', value: `${c.familyName} ${c.givenName}` },
        {
          text: 'ふりがな',
          value: `${c.familyNameKana} ${c.givenNameKana}`,
        },
        { text: '年齢', value: c.age },
        { text: '性別', value: c.gender },
        { text: '誕生日', value: c.birthday },

        {
          text: '住所',
          value: `${c.postcode} ${c.prefecture} ${c.city} ${c.address} ${c.building}`,
        },
        { text: '電話番号', value: c.phoneNumber },
        { text: 'メールアドレス', value: c.email },
        {
          text: 'お知らせを受け取る',
          value: c.receivesInfo ? '受け取る' : '受け取らない',
        },
        {
          text: '備考',
          value: c.note,
        },
        {
          text: '施術の注意点',
          value: c.operationNote,
        },
        {
          text: 'スターターパス購入日',
          value: c.starterPassFrom,
        },
        {
          text: 'スターターパス有効期限',
          value: c.starterPassTo,
        },
      ]
    },
    patchTestText() {
      let c = this.customer
      if (!c) return null
      switch (c.patchTest) {
        case null:
          return '未登録'
        case true:
          return '実施する'
        case false:
          return '実施しない'
      }
      return null
    },
    counselingAnswers() {
      let c = this.customer
      if (!c) return null
      return [
        { text: 'パッチテスト（旧）', value: this.patchTestText },
        ...this.questions.map(this.getAnswer).filter(x => Boolean(x.value)),
      ]
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.customer = await this.$api.customers(this.id).get()
      this.questions = await this.$apiV3
        .counselingQuestions()
        .list({ all: true })
        .then(({ results }) => results)

      this.$nextTick(() => {
        this.fixHeight()
      })
    },
    getAnswer(question) {
      let { id, shortText, options } = question
      let answers = this.customer.counselingAnswers.filter(
        x => x.question === id
      )
      return {
        text: shortText,
        value: answers
          .map(({ option, text }) => {
            let opt = options.find(x => x.id === option)
            if (!opt) return null
            if (text) return `${opt.text}（${text}）`
            return opt.text
          })
          .filter(x => x)
          .join('、 '),
      }
    },

    fixHeight() {
      const rect = this.$refs.tableContainer.getBoundingClientRect()
      if (rect) {
        const footRect = this.$refs.bottom.getBoundingClientRect()

        let h = window.innerHeight - rect.top - footRect.height - 20
        if (h > 300) this.tableHeight = h + 'px'
      }
    },
  },
}
</script>

<style></style>
